
export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'click':
            new_state = {
                ...state,
                hi: state.hi + 1
            }
            break;
        case 'add_question':
            new_state = {
                ...state,
                questions: [...state.questions, action.question]
            }
            break;
        case 'remove_question':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=> {
                    if(action.index != index){
                        return q
                    } else {
                        return null
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'add_answer':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=> {
                    if(action.index != index){
                        return q
                    } else {
                        return {
                            ...q,
                            answers: [...(q.answers || []), action.answer]
                        }
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'update_answer':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=> {
                    if(action.questionIndex != index){
                        return q
                    } else {
                        return {
                            ...q,
                            answers: (q.answers || []).map((answer, j)=>{
                                if(j === action.index){
                                    return {
                                        ...answer,
                                        ...action.answer
                                    }
                                } else {
                                    return answer
                                }
                            })
                        }
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'update_all_answers':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=> {
                    if(action.questionIndex != index){
                        return q
                    } else {
                        return {
                            ...q,
                            answers: (q.answers || []).map((answer, j)=>{
                                    return {
                                        ...answer,
                                        ...action.answer
                                    }
                            })
                        }
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'remove_answer':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=> {
                    if(action.questionIndex != index){
                        return q
                    } else {
                        return {
                            ...q,
                            answers: (q.answers || []).map((answer, j)=>{
                                if(j === action.index){
                                    return null
                                } else {
                                    return answer
                                }
                            }).filter((a)=> a != null)
                        }
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'change_question_value':
            new_state = {
                ...state,
                questions: state.questions.map((q, index)=>{
                    if(index === action.index){
                        return {
                            ...q,
                            [action.field] : action.value
                        }
                    } else {
                        return q
                    }
                })
            }
            break;

    }

    return new_state

}
