import React, {Component} from "react"
import {connect} from 'react-redux'
import {updateAnswer, updateAllAnswers, removeAnswer} from '../../redux/actions'

class AnswerInput extends Component {
    constructor(props){
        super(props)
        this.changeType = this.changeType.bind(this)
    }

    changeType(e){
        const type = e.target.value
        const defaultValue = this.defaultValue(type)
        this.props.updateAllAnswers(this.props.questionIndex, this.props.index, { type: type, name: defaultValue })

    }

    defaultValue(type){
        switch(type){
            case 'icons':
                return 'happy_face'
            default:
                return ''

        }
    }

    render() {
        return (
            <div key={this.props.index} className={`w100 nowrap answer ${this.props.answer.type}`} >
                <select
                    className={'answer-type'}
                    name={'answer-type'}
                    value={this.props.answer.type}
                    onChange={(e)=>this.changeType(e)}
                >
                    <option value={'icons'}>Icons</option>
                    <option value={'text'}>Text</option>
                    <option value={'long_answer'}>Long Answer</option>
                </select>
                {
                    this.props.answer.type === 'text' &&
                    <input
                           type={'text'}
                           className={'inline-block'}
                           placeholder={'Type Response Here'}
                           onChange={(e)=>this.props.updateAnswer(this.props.questionIndex, this.props.index, { name: e.target.value })}
                           value={this.props.answer.name}
                    />
                }
                {
                    this.props.answer.type === 'icons' &&
                    <select
                        className={'icon-selector'}
                        value={this.props.answer.name}
                        onChange={(e)=>this.props.updateAnswer(this.props.questionIndex, this.props.index, { name: e.target.value })}
                    >
                        <option value={'happy_face'}>Happy Face</option>
                        <option value={'semi_happy_face'}>Semi Happy Face</option>
                        <option value={'meh_face'}>Meh Face</option>
                        <option value={'semi_sad_face'}>Semi-Sad Face</option>
                        <option value={'sad_face'}>Sad Face</option>
                        <option value={'thumbs_up'}>Thumbs Up</option>
                        <option value={'thumbs_down'}>Thumbs Down</option>
                    </select>
                }
                <span className={'remove-answer'}
                     onClick={()=>this.props.removeAnswer(this.props.questionIndex, this.props.index)}
                >
                    remove
                </span>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    updateAnswer,
    removeAnswer,
    updateAllAnswers,
})(AnswerInput)
