import React, {Component} from "react"
import { connect } from 'react-redux'
import { changeQuestionField, removeQuestion, addAnswer } from '../redux/actions'
import AnswerInput from "./Question/AnswerInput";

class Question extends Component {

    constructor(props){
        super(props)
        this.newAnswer = this.newAnswer.bind(this)
    }

    defaultValue(type){
        switch(type){
            case 'icons':
                return 'happy_face'
            default:
                return ''

        }
    }

    newAnswer(){
        if(this.props.question.answers||[].length > 0){
            const answer = this.props.question.answers[0]
            return { name: this.defaultValue(answer.type), type: answer.type }
        } else {
          return { name: 'happy_face', type: 'icons'}
        }
    }

    render () {
        console.log(this.props.store)
        return (
            <div className={'question-config question'}>
                <b>Question:</b>
                <div>
                    <div className="remove-question" onClick={()=>this.props.removeQuestion(this.props.index)}>delete</div>
                    <input
                        onChange={(e)=>{this.props.changeQuestionField(this.props.index, 'text', e.target.value)}}
                        value={this.props.question.text}
                        type={'text'}
                    />
                </div>
                <div className={'margin_top_sm'}>
                    <b>Answers: </b>
                    {
                        (this.props.question.answers || []).map((answer, i)=> {
                            return (
                                <div key={i}>
                                    <AnswerInput
                                        questionIndex={this.props.index}
                                        index={i}
                                        answer={answer}
                                    />
                                </div>
                            )
                        })
                    }
                    <div onClick={()=> this.props.addAnswer(this.props.index, this.newAnswer())}
                        className={'add-answer'}>
                        Add Answer
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    changeQuestionField,
    removeQuestion,
    addAnswer
})(Question)
