import React, {Component} from "react"
import {connect} from 'react-redux'
import {click, addSurveyMapping, removeSurveyMapping, changeSurveyMappingField} from "./redux/actions";

class SurveySelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            config: this.props.config || {}
        }
    }

    render() {
        console.log(this.props.store)
        return (
            <div className={'question-config'}>
                <form action={`/event/${this.props.store.event.id}/set_surveys`} method={'post'}>
                    <input type='text' hidden name={'authenticity_token'}
                           defaultValue={this.props.authenticationToken}/>
                    {
                        this.props.store.surveyMappings.map((sm, index) => {
                            return (
                                <div className={'question-config question'}>
                                    <select
                                        name={`survey_${index}[survey_id]`}
                                        defaultValue={sm.survey_id}
                                        onChange={(e) => this.props.changeSurveyMappingField(index, 'survey_id', Number(e.target.value))}
                                    >
                                        <option value={-1}>Select Survey</option>
                                        {
                                            this.props.store.surveys.map((s) => {
                                                return (
                                                    <option value={s.id}>{s.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="remove-survey-mapping"
                                         onClick={() => this.props.removeSurveyMapping(index)}>delete
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="w100 text-right">
                        <div className={'margin_bottom_md add-question inline-block no-select'}
                             onClick={() => this.props.addSurveyMapping({value: -1})}>
                            &nbsp;Add Survey To Event&nbsp;
                        </div>
                        <div>
                            <div className={'margin_top_md margin_bottom_md inline-block'}>
                                <input type={'submit'} value={'Save'}/>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    click,
    addSurveyMapping,
    removeSurveyMapping,
    changeSurveyMappingField,
})(SurveySelector)
