import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './redux/reducer';
import Body from './SurveySelector';

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

const initial_state = { hi: 0 };

function mapStateToProps(state) {
    return {
        store: state,
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class SurveySelectorParent extends React.Component {

    render(){
        const mappedSurveys = (this.props.mapped_surveys || []).map((ms)=>{
            return {
                ...ms,
                id: ms.survey_id,
                name: this.props.surveys.find((s)=> s.id === ms.survey_id)
            }
        })

        const store = createStore(rootReducer, {
            ...initial_state,
            event: this.props.event,
            surveys: this.props.surveys,
            mapped_surveys: this.props.mapped_surveys,
            surveyMappings: mappedSurveys,
            authenticationToken,
        });
        return (
            <Provider store={store}>
                <ConnectedBody

                />
            </Provider>
        );
    }
}

export default SurveySelectorParent
