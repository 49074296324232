import React, {Component} from "react"
import {connect} from 'react-redux'
import {addQuestion} from "./redux/actions";
import Question from './QuestionConfig/Question'

class QuestionConfig extends Component {

    constructor(props) {
        super(props)
        this.state = {
            config: this.props.config || {}
        }
    }

    render() {
        return (
            <div className={'question-config'}>
                <div>
                    {
                        this.props.store.questions.map((question, index) => {
                            return (
                                <Question
                                    index={index}
                                    question={question}
                                />
                            )
                        })
                    }
                </div>
                <div className={'add-question'} onClick={() => this.props.addQuestion({text: 'Question text??? '})}>
                    Add Question (+)
                </div>
                <input type={'text'} hidden value={JSON.stringify(this.props.store.questions)} name={'survey[config]'}/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        store: state
    };
}

export default connect(mapStateToProps, {
    addQuestion
})(QuestionConfig)
