import React from 'react';
import {Provider, connect} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './redux/reducer';
import Body from './QuestionConfig';

const csfrToken = document.getElementsByName('csrf-token')[0];
const authenticationToken = csfrToken != null ? csfrToken.getAttribute('content') : '';

const initial_state = { hi: 0 };

function mapStateToProps(state) {
    return {
        store: state,
        authenticationToken: authenticationToken
    }
}

const ConnectedBody = connect(mapStateToProps, {})(Body);

class QuestionConfigParent extends React.Component {

    get config(){
        try {
            console.log(JSON.parse(this.props.config))
            return JSON.parse(this.props.config)
        } catch(err) {
            return []
        }
    }

    render(){
        const questions = this.config
        const store = createStore(rootReducer, {
            ...initial_state,
            questions: questions || [],
        });

        return (
            <Provider store={store}>
                <ConnectedBody

                />
            </Provider>
        );
    }
}

export default QuestionConfigParent