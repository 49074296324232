export default (state = {}, action) => {
    let new_state = state;

    switch (action.type) {
        case 'click':
            new_state = {
                ...state,
                hi: state.hi + 1
            }
            break;
        case 'add_survey_mapping':
            new_state = {
                ...state,
                surveyMappings: [ ...state.surveyMappings, action.survey_mapping ]
            }
            break;
        case 'remove_survey_mapping':
            new_state = {
                ...state,
                surveyMappings: state.surveyMappings.map((sm, index)=> {
                    if(action.index != index){
                        return sm
                    } else {
                        return null
                    }
                }).filter((q)=> q != null)
            }
            break;
        case 'change_survey_mapping_value':
            console.log(action)
            new_state = {
                ...state,
                surveyMappings: state.surveyMappings.map((sm, index)=> {
                    console.log(action.index, index)
                    if(action.index != index){
                        return {
                            ...sm,
                            [action.field]: action.value,
                        }
                    } else {
                        return sm
                    }
                }).filter((q)=> q != null)
            }
            break;
    }

    return new_state

}